<template>
  <div class="wrapper tw30-wrapper">
    <div class="tw30-heading">
      <div class="tw30-heading-logo"></div>
    </div>

    <Row>
      <Column md="1"></Column>
      <Column md="10">
        <div class="tw30-heading-text mb-4 mt-4">
          <p>On 5 October 1990 a Norwegian publisher launched a blue paper with a vision to cover the global business of shipping and its unique characters. Here you can look back over more than 1,500 editions to see who was making the news and why at key times in the industry’s history.</p>
        </div>
      </Column>
      <Column md="1"></Column>
    </Row>

    <Row>
      <Column
        md="5"
        sm="5"
        xs="4"></Column>
      <Column
        md="2"
        sm="2"
        xs="4">
        <div class="tw30-year-dropdown">
          <DropdownField
            v-model="selectedYear"
            :options="[...tw30years]"
            @input="fetchEpapersByYear"
            label="SELECT YEAR"/>
        </div>
      </Column>
      <Column
        md="5"
        sm="5"
        xs="4"></Column>
      <div class="tw30-year-borderline"></div>
    </Row>
    <Row>
      <Column md="1"></Column>
      <Column md="10">
        <div :class="['spinner-container', { 'd-block': epapersLoading }]">
          <div
            class="spinner-border"
            role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <Row :style="epapersLoading && blurStyle">
          <Column
            v-for="{title, imageAlternative, date, url} in ePapers"
            :key="combineKeys(date, title)"
            md="2"
            sm="3"
            xs="6"
            class="mb-4">
            <div>
              <a
                :href="generateLinks(url)"
                class="text-reset text-center"
                target="_blank">
                <card-image
                  :source="imageAlternative"
                  :alt="title"
                  :caller="imageCaller"/>
                <div class="text-reset text-center ff-flama fs-sm">{{ formatDate(date) }}</div>
              </a>
            </div>
          </Column>
        </Row>
      </Column>
      <Column md="1"></Column>
    </Row>
    <Row>
      <Column md="1"></Column>
      <Column md="10">
        <div class="tw30-footer-text mt-4 mb-4">
          <p>
            Celebrating 30 years as the go-to news source in shipping, TradeWinds continues to offer market-leading coverage as it unpacks the key issues.
            Our global editorial team of experts offer peerless independent journalism, insight and business intelligence that simply can’t be found anywhere else.
            We get close to the key movers and shakers on the ground in the US, UK, Norway, Greece, Singapore, China, India and all points in-between.
            Shipping never sleeps – so don’t miss a moment with TradeWinds’ unrivalled round-the-clock coverage.
          </p>
        </div>
      </Column>
      <Column md="1"></Column>
    </Row>
  </div>
</template>

<script>
import axios from 'axios';
import { decodeTitle, moment } from 'global-utils';
import { Page } from 'global-components';

export default {
  name: 'tw-30yrs',
  dataResolver: 'tw30yrsEpapers',
  extends: Page,
  data() {
    return {
      epapersLoading: false,
      tw30years: this.generateYearArray(1990, 2020),
      ePapers: this.$store.state.selectedEpapers.items || [],
      imageCaller: this.$options.name,
    };
  },
  computed: {
    selectedYear: {
      get() {
        return this.$store.state.selectedEpaperYear || 2020;
      },
      set(year) {
        this.$store.commit('storeSelectedEpaperYear', year);
      }
    },
    blurStyle() {
      return {
        '-webkit-filter': `blur(5px)`,
        '-moz-filter': `blur(5px)`,
        '-ms-filter': `blur(5px)`,
        '-o-filter': `blur(5px)`,
        filter: `blur(5px)`,
      };
    },
  },
  methods: {
    decodeTitle: decodeTitle,
    generateYearArray(start, end) {
      const yearArr = Array.from({ length: (end - start) + 1 }, (v, k) => k + start);
      yearArr.reverse();
      return yearArr;
    },
    generateLinks: function (url) {
      return url + '&requireLogin=' + (this.requireLogin || false);
    },
    combineKeys: function (date, title) {
      return (date + '-' + title).replace(/ /gi, '-');
    },
    formatDate(date) {
      moment.locale(this.$store.state.publication.language);
      return moment(date).format('D MMM YYYY');
    },
    async fetchEpapersByYear(year) {
      this.epapersLoading = true;
      try {
        const selectedEpapers = await axios.get('/global/api/epapersByYear/' + year);
        this.selectedYear = year;
        this.ePapers = selectedEpapers.data.items;
        this.$store.commit('storeSelectedEpapers', selectedEpapers.data);
      } catch (e) {
        console.error(e);
      }
      this.epapersLoading = false;
    },
  },
};
</script>
